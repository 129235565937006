import {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {routes} from '../../../routes';
import {useEventReviewContext} from '../contexts/EventReviewContext';

export const useReviewEvent = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const currentTimeRef = useRef<number>(currentTime);
  const [duration, setDuration] = useState<number>(0);
  const [playing, setPlaying] = useState<boolean>(false);
  const [chipListValues, setChipListValues] = useState<
    Array<string | undefined>
  >([]);
  const navigate = useNavigate();
  const {
    setSnapshotList,
    setOnEventSelectedShortcuts,
    setKeyBuffer,
    keyBuffer,
    handleFaultyClip,
    handleNoIssue,
    lookupTableData,
  } = useEventReviewContext();

  const handleLoadedMetadata = async () => {
    if (videoRef.current != null) {
      videoRef.current.playbackRate = 1.5;
      await videoRef.current.play();
      setDuration((prev) => Math.max(prev, videoRef?.current?.duration ?? 0));
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef?.current?.currentTime ?? 0);
  };

  const handleSliderChange = (
    _event: Event,
    newTimeValue: number | number[],
  ) => {
    if (Array.isArray(newTimeValue)) return;
    setCurrentTime(newTimeValue);

    if (videoRef.current != null) {
      videoRef.current.currentTime = newTimeValue;
    }
  };

  const togglePlay = async () => {
    try {
      if (videoRef.current != null) {
        if (playing) {
          videoRef.current.pause();
        } else {
          await videoRef.current.play();
        }
      }
      setPlaying(!playing);
    } catch (error) {}
  };

  const handleVideoEnd = useCallback(() => {
    setTimeout(() => {
      navigate(routes.eventReview.feedback);
    }, 500);
  }, [navigate]);

  const handleKeyBuffer = useCallback(() => {
    if (keyBuffer !== '') {
      if (keyBuffer === 'ce') {
        handleFaultyClip();
        setKeyBuffer('');
        return;
      }

      if (keyBuffer === 'n') {
        handleNoIssue();
      }

      const checkbox = lookupTableData.combinedLookup[keyBuffer];
      if (checkbox != null) {
        setOnEventSelectedShortcuts((prev) =>
          prev.includes(keyBuffer)
            ? prev.filter((item) => item !== keyBuffer)
            : [...prev, keyBuffer],
        );

        setChipListValues((prev) =>
          prev.includes(checkbox.name)
            ? prev.filter((item) => item !== checkbox.name)
            : [...prev, checkbox.name],
        );
      }
      setKeyBuffer('');
      setTimeout(() => {
        setKeyBuffer('');
      }, 555);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    keyBuffer,
    setKeyBuffer,
    setOnEventSelectedShortcuts,
    handleNoIssue,
    handleFaultyClip,
  ]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const key = event.key.toLowerCase();
      setKeyBuffer((prev) => prev + key);
    };

    const bufferTimeout = setTimeout(handleKeyBuffer, 300);

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      clearTimeout(bufferTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyBuffer, handleKeyBuffer]);

  useEffect(() => {
    currentTimeRef.current = currentTime;

    const handleSpacebarPress = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        setSnapshotList((prevList) => [...prevList, currentTimeRef.current]);
      }
    };

    const handleContextMenu = (e: MouseEvent) => {
      e.preventDefault();
    };

    window.addEventListener('keydown', handleSpacebarPress);
    window.addEventListener('contextmenu', handleContextMenu);

    return () => {
      window.removeEventListener('keydown', handleSpacebarPress);
      window.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [currentTime, setSnapshotList]);

  return {
    videoRef,
    handleLoadedMetadata,
    handleTimeUpdate,
    handleSliderChange,
    togglePlay,
    handleVideoEnd,
    duration,
    currentTime,
    playing,
    setPlaying,
    chipListValues,
  };
};
