import {type RefObject, useEffect, useMemo} from 'react';
import {toast} from 'react-toastify';

import type {Behaviour} from '@onroadvantage/onroadvantage-api';
import {type FormikProps} from 'formik/dist/types';

import {useFormValues} from './useFormValues';
import {
  hasOnlySoundBehaviours,
  processLookupObject,
} from '../utils/feedbackUtils';

export interface ExtendedBehaviour extends Behaviour {
  shortcut?: string;
}

export const useCheckUnselectNoIssue = ({
  formRef,
  behaviours,
}: {
  formRef: RefObject<FormikProps<Record<string, boolean>>>;
  behaviours?: ExtendedBehaviour[] | Behaviour[];
}) => {
  const formValues = useFormValues(formRef);
  const cleanedKeys = useMemo(
    () => processLookupObject(formValues),
    [formValues],
  );

  const onlySoundsSelected = hasOnlySoundBehaviours(cleanedKeys, behaviours);

  const disabledBehaviour =
    formValues['__lookupKey: n__No Issue'] &&
    Object.entries(formValues).filter(([, value]) => value).length > 1;

  useEffect(() => {
    if (!onlySoundsSelected && disabledBehaviour) {
      void formRef?.current?.setValues((current) => ({
        ...current,
        '__lookupKey: n__No Issue': false,
      }));

      toast(
        'Event no longer marked as non issue because a behaviour was selected',
        {type: 'warning'},
      );
    }
  }, [disabledBehaviour, formRef, onlySoundsSelected]);
};
