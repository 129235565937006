import {useMemo} from 'react';

import {type EventDetailsData} from './useEventFeedback';
import {
  formatDateTime,
  formatDriverName,
  formatSpeed,
} from '../../videoEvents/utils/VideoEventDetailUtils';
import {useEventReviewContext} from '../contexts/EventReviewContext';
import {calculateLeadTime} from '../utils/feedbackUtils';
import {type WebSocketHook} from '../WebsocketTypes';

interface EventData {
  id?: string;
  customerEventId?: string;
  driverFirstName?: string;
  driverLastName?: string;
  creationDate?: string;
  recordDateUTC?: string;
  speed?: number;
  erSerialNumber?: string;
}

const useActiveEventData = (webSocket: WebSocketHook) => {
  return useMemo(() => {
    const activeEvent: EventData = webSocket.events[0]?.data.data ?? {};
    return {
      event: activeEvent?.customerEventId ?? 'Unknown',
      driver: formatDriverName(
        activeEvent.driverFirstName,
        activeEvent.driverLastName,
      ),
      creationDate: formatDateTime(activeEvent.creationDate),
      recordedDate: formatDateTime(activeEvent.recordDateUTC),
      speed: formatSpeed(activeEvent.speed),
      serial: activeEvent.erSerialNumber ?? 'Unknown',
      leadTime: calculateLeadTime(activeEvent.recordDateUTC),
    };
  }, [webSocket]);
};

const useOrganisationData = (eventDetailsData?: EventDetailsData) => {
  return useMemo(
    () => ({
      org: eventDetailsData?.group?.fullPath?.split('\\')[0] ?? 'Unknown',
      orgGroupName:
        eventDetailsData?.group?.organisationGroup?.name ?? 'Unknown',
      vehicle: eventDetailsData?.vehicle?.description ?? 'Unknown',
      eventTrigger: eventDetailsData?.trigger?.name ?? 'Unknown',
    }),
    [
      eventDetailsData?.group?.fullPath,
      eventDetailsData?.group?.organisationGroup?.name,
      eventDetailsData?.trigger?.name,
      eventDetailsData?.vehicle?.description,
    ],
  );
};

export const useEventDetails = ({
  eventDetailsData,
}: {
  eventDetailsData?: EventDetailsData;
}) => {
  const {webSocket} = useEventReviewContext();

  const activeEventData = useActiveEventData(webSocket);
  const organisationData = useOrganisationData(eventDetailsData);

  return {
    ...activeEventData,
    ...organisationData,
  };
};
