import {useEffect, useMemo, useState} from 'react';
import {toast} from 'react-toastify';

import {
  type OperationalEventType,
  type Vehicle,
  type OrganisationGroupExternal,
  type BehaviourOutcomeCalculate,
  type HindsightStandardConfig,
} from '@onroadvantage/onroadvantage-api';

import {
  eventTriggerApi,
  hindsightConfigApi,
  organisationGroupExternalApi,
  vehicleApi,
} from '../../../api';
import {useEventReviewContext} from '../contexts/EventReviewContext';

export interface EventDetailsData {
  group: OrganisationGroupExternal;
  vehicle: Vehicle;
  trigger: OperationalEventType;
}

export const useEventFeedback = () => {
  const {webSocket} = useEventReviewContext();
  const [eventDetailsData, setEventDetailsData] = useState<EventDetailsData>();
  const [hindsightConfigData, setHindsightConfigData] = useState<
    HindsightStandardConfig[]
  >([]);
  const {groupId, vehicleId, triggerId} = useMemo(() => {
    const eventData = webSocket.events[0]?.data.data;
    return {
      groupId: eventData?.groupId,
      vehicleId: eventData?.vehicleId,
      triggerId: eventData?.eventTriggerId,
    };
  }, [webSocket]);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const vehicleResponse =
          await vehicleApi.apiVehicleExternalExternalIdGet({
            externalId: vehicleId,
            providerName: 'lytx',
          });

        const [organisationResponse, triggerResponse] = await Promise.all([
          organisationGroupExternalApi.apiOrganisationGroupExternalOrganisationGroupExternalIdGet(
            {
              organisationGroupExternalId: groupId,
            },
          ),

          eventTriggerApi.apiEventTriggerExternalReferenceExternalReferenceGet({
            externalReference: triggerId?.toString(),
            telematicsConfigId: Number(vehicleResponse.telematicsConfigId),
          }),
        ]);

        setEventDetailsData({
          group: organisationResponse ?? null,
          vehicle: vehicleResponse ?? null,
          trigger: triggerResponse ?? null,
        });
      } catch {
        toast('Error fetching event details:', {type: 'error'});
      }
    };

    if (groupId != null && vehicleId != null && triggerId != null) {
      void fetchEventDetails();
    }
  }, [groupId, vehicleId, triggerId]);

  const generateHindsightData = async (
    outcomes?: BehaviourOutcomeCalculate[] | null,
  ) => {
    if (outcomes == null || eventDetailsData?.group?.id == null) {
      return;
    }

    const outcomeIds = outcomes
      .map((outcome: BehaviourOutcomeCalculate) => outcome.id)
      .filter((id) => id != null);

    const response =
      await hindsightConfigApi.apiHindsightStandardConfigCalculateGet({
        behaviourOutcomeIds: outcomeIds,
        organisationGroupExternalId: eventDetailsData?.group.id,
      });

    if (response?.items != null) {
      setHindsightConfigData(response.items);
    }
  };

  return {
    eventDetailsData,
    generateHindsightData,
    hindsightConfigData,
  };
};
