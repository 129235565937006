import {useState} from 'react';
import {toast} from 'react-toastify';

import {type BehaviourOutcomeCalculate} from '@onroadvantage/onroadvantage-api';

import {behaviourOutcomeApi} from '../../../api';

export const useBehaviourOutcomes = () => {
  const [behaviourOutcomes, setBehaviourOutcomes] =
    useState<BehaviourOutcomeCalculate[]>();

  const getBehaviourOutcomes = async (behaviourIds: number[]) => {
    if (behaviourIds == null || behaviourIds.length === 0) {
      return null;
    }
    try {
      const {items} = await behaviourOutcomeApi.apiBehaviourOutcomeCalculateGet(
        {
          behaviourIds,
        },
      );

      if (items != null) {
        const filteredItems = items.filter(
          (item) => item.name != null && item.name !== '',
        );

        const hasFatigueCritical = filteredItems.some(
          (beh) => beh.name === 'Fatigue - Critical',
        );

        const finalFilteredItems = hasFatigueCritical
          ? filteredItems.filter((beh) => beh.name !== 'Fatigue - Possible')
          : filteredItems;
        setBehaviourOutcomes(finalFilteredItems ?? undefined);
      }
      return items;
    } catch {
      toast('Error getting behaviour outcomes', {type: 'error'});
      return undefined;
    }
  };

  return {behaviourOutcomes, getBehaviourOutcomes};
};
