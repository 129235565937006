import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';

import {config} from '../../../config';
import {OverlayLoader} from '../../common/components/loader/OverlayLoader';
import {ReviewEventChipist} from '../components/ReviewEventChipList';
import {EventReviewVideoTimeline} from '../components/timeline/EventReviewVideoTimeline';
import {useEventReviewContext} from '../contexts/EventReviewContext';
import {useReviewEvent} from '../hooks/useReviewEvent';

export default function ReviewEvent() {
  const {
    currentTime,
    duration,
    handleLoadedMetadata,
    handleSliderChange,
    handleTimeUpdate,
    handleVideoEnd,
    playing,
    togglePlay,
    videoRef,
    setPlaying,
    chipListValues,
  } = useReviewEvent();

  const {webSocket} = useEventReviewContext();
  const videoLoading =
    (videoRef.current == null || videoRef.current.readyState < 1) ?? false;

  return (
    <ReviewContainer
      bgcolor="#EFEFEF"
      m={config.theme.gutterSpacing}
      borderRadius="16px"
      p="36px"
    >
      <OverlayLoader
        loading={webSocket.events.length === 0 && !webSocket.queueEmpty}
        label="Initialising Event Flow"
        glassMorphismOptions={{transparency: 1}}
      />
      <OverlayLoader
        loading={webSocket.queueEmpty && webSocket.events.length === 0}
        label="No Events Left In Queue, Getting More Events..."
        glassMorphismOptions={{transparency: 1}}
      />
      <Stack position="relative">
        <div className="event-container">
          {webSocket.events[0]?.data.media.accessURI != null && (
            <>
              <OverlayLoader
                loading={videoLoading}
                label="Loading video"
                glassMorphismOptions={{transparency: 1}}
              />
              <PlayBackSpeedContainer label="1.5x" />
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video
                src={webSocket.events[0]?.data.media.accessURI}
                className="w-full"
                onLoadedMetadata={handleLoadedMetadata}
                onTimeUpdate={handleTimeUpdate}
                ref={videoRef}
                onPlay={() => {
                  setPlaying(true);
                }}
                onEnded={handleVideoEnd}
                style={{width: '100%', height: '100%', borderRadius: '16px'}}
              >
                Your browser does not support the video tag.
              </video>
            </>
          )}
        </div>
        <EventReviewVideoTimeline
          forwardDisabled
          currentTime={currentTime}
          duration={duration}
          handleSliderChange={handleSliderChange}
          playing={playing}
          togglePlay={togglePlay}
        />
      </Stack>
      <ReviewEventChipist chipListValues={chipListValues} />
    </ReviewContainer>
  );
}

const ReviewContainer = styled(Stack)(() => ({
  '& > .event-container': {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
  },
}));

const PlayBackSpeedContainer = styled(Chip)(() => ({
  position: 'absolute',
  right: '12px',
  color: '#FFFFFF',
  fontWeight: 'bold',
  fontSize: '14px',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  top: '12px',
  '& .MuiChip-label': {
    marginBottom: '2px',
  },
}));
