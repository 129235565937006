import {useMemo} from 'react';

import MailIcon from '@mui/icons-material/Mail';
import MessageIcon from '@mui/icons-material/Message';
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {type ContactDump} from '@onroadvantage/onroadvantage-api';

export interface NotificationFlags {
  sms: boolean;
  email: boolean;
  phone: boolean;
}

interface NotificationRowProps
  extends Partial<Pick<ContactDump, 'name' | 'cellphoneNumber' | 'email'>> {
  onCallClicked: (contactName: string) => void;
  enabledNotificationTypes?: NotificationFlags;
}

export function NotificationContactRow({
  name,
  cellphoneNumber,
  email,
  onCallClicked,
  enabledNotificationTypes,
}: NotificationRowProps) {
  const contactMethods = useMemo(
    () => [
      {
        type: 'phone',
        icon: <PhoneIcon />,
        href: `tel:${cellphoneNumber}`,
        isLink: true,
        onClick: () => {
          onCallClicked(name ?? '');
        },
      },
      {
        type: 'email',
        icon: <MailIcon />,
        isLink: false,
      },
      {
        type: 'sms',
        icon: <MessageIcon />,
        isLink: false,
      },
    ],
    [cellphoneNumber, name, onCallClicked],
  );

  return (
    <TableRow>
      <TableCell>
        <Typography variant="subtitle1">{name}</Typography>
      </TableCell>
      <TableCell>{cellphoneNumber}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell align="right">
        {contactMethods.map((method) => {
          const isDisabled =
            enabledNotificationTypes != null
              ? !enabledNotificationTypes[
                  method.type as keyof NotificationFlags
                ]
              : false;

          const commonProps = {
            key: method.type,
            size: 'small' as const,
            'aria-label': method.type,
            disabled: isDisabled,
          };

          if (method.isLink) {
            return (
              <Link
                href={method.href}
                onClick={method.onClick}
                sx={{
                  color: isDisabled
                    ? 'rgba(0, 0, 0, 0.10)'
                    : 'rgba(0, 0, 0, 0.60)',
                  padding: '5px',
                  position: 'relative',
                  top: '8px',
                  ...(isDisabled && {pointerEvents: 'none'}),
                }}
                {...commonProps}
                key={method.href}
              >
                {method.icon}
              </Link>
            );
          }

          return (
            <IconButton
              {...commonProps}
              key={method.href}
              sx={{
                color: isDisabled
                  ? 'rgba(0, 0, 0, 0.10)'
                  : 'rgba(0, 0, 0, 0.60)',
              }}
            >
              {method.icon}
            </IconButton>
          );
        })}
      </TableCell>
    </TableRow>
  );
}
