import {useState} from 'react';

import {
  type BehaviourOutcomeContactOrganisationGroupExternal,
  type EarlyWarningEventComment,
} from '@onroadvantage/onroadvantage-api';

import {type NotificationFlags} from '../components/feedback/contacts/NotificationContactRow';
import {useEventReviewContext} from '../contexts/EventReviewContext';

const REASON_CODE_ANSWERED = (contact: string) => `${contact} answered`;
const REASON_CODE_UNANSWERED = (contact: string) => `${contact} did not answer`;

export interface DeletableComment {
  [key: symbol]: number;
  text: string;
  commentTime: Date;
}
export const useNotificationContacts = ({
  eventDetailsComments,
}: {
  eventDetailsComments?: EarlyWarningEventComment[];
}) => {
  const {setNotificationComments, notificationComments} =
    useEventReviewContext();
  const [comment, setComment] = useState<string>('');
  const [callTriggeredContactNames, setCallTriggeredContactNames] = useState<
    string[]
  >([]);

  const handleCallTriggered = (contactName: string) => {
    setNotificationComments([
      ...notificationComments,
      {
        text: `Call triggered for ${contactName}`,
        commentTime: new Date(),
        [Symbol('iterator')]: 1,
      },
    ]);

    setCallTriggeredContactNames((prevState) => [...prevState, contactName]);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() !== '') {
      setNotificationComments([
        ...notificationComments,
        {
          text: comment,
          commentTime: new Date(),
          [Symbol('iterator')]: notificationComments.length + 1,
        },
      ]);
      setComment('');
    }
  };

  const handleReasonCode = (reason: string | null) => {
    if (reason == null || reason?.trim() === '') {
      return;
    }
    setNotificationComments([
      ...notificationComments,
      {
        text: reason,
        commentTime: new Date(),
        [Symbol('iterator')]: notificationComments.length + 1,
      },
    ]);
  };

  const handleCommentDelete = (selectedComment: DeletableComment) => {
    const symbolKey = Object.getOwnPropertySymbols(selectedComment)[0];
    const commentSymbolValue = selectedComment[symbolKey];

    setNotificationComments((prev) =>
      prev.filter((c) => {
        const currentSymbolKey = Object.getOwnPropertySymbols(c)[0];
        return c[currentSymbolKey] !== commentSymbolValue;
      }),
    );
  };

  const extractNotificationTypes = (
    data: BehaviourOutcomeContactOrganisationGroupExternal[],
  ): NotificationFlags => {
    return {
      sms: data.some(
        (item) =>
          item.notificationTypes?.some((nt) => nt.name === 'sms') ?? false,
      ),
      email: data.some(
        (item) =>
          item.notificationTypes?.some((nt) => nt.name === 'email') ?? false,
      ),
      phone: data.some(
        (item) =>
          item.notificationTypes?.some((nt) => nt.name === 'phone') ?? false,
      ),
    };
  };

  const commentsList = eventDetailsComments ?? notificationComments;

  return {
    comment,
    setComment,
    handleCallTriggered,
    handleCommentSubmit,
    commentsList,
    handleCommentDelete,
    handleReasonCode,
    REASON_CODE_ANSWERED,
    REASON_CODE_UNANSWERED,
    callTriggeredContactNames,
    extractNotificationTypes,
  };
};
