import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {type WebMasterTripOptimised} from '@onroadvantage/onroadvantage-api';

import {routes} from '../../../routes';
import {OverlayLoader} from '../../common/components/loader/OverlayLoader';
import {type VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTablePaper} from '../../common/components/table/styledComponents/VantageTablePaper';
import {MemoizedVantageTable} from '../../common/components/table/VantageTable';
import {useFiltering} from '../../common/hooks/useFiltering';
import {usePagination} from '../../common/hooks/usePagination';
import {useSorting} from '../../common/hooks/useSorting';
import {useUserStore} from '../../common/stores/userStore';
import {TripsContractFilterField} from '../components/TripsContractFilterField';
import {TripsDetail} from '../components/TripsDetail';
import {useDeleteTrip} from '../hooks/useDeleteTrip';
import {useDownloadTrips} from '../hooks/useDownloadTrips';
import {useLoadTrips} from '../hooks/useLoadTrips';

const tableConfig: VantageTableConfig<WebMasterTripOptimised> = {
  columns: [
    {
      name: 'tripNumber',
      label: 'Trip Number',
      type: 'string',
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'contractCode',
      label: 'Contract',
      type: 'string',
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'driverName',
      label: 'Driver',
      type: 'string',
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'vehicleRegistrationNumber',
      label: 'Registration Number',
      type: 'string',
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'fleetNumber',
      label: 'Fleet Number',
      type: 'string',
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'upliftNodeName',
      label: 'Collection Location',
      type: 'string',
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'offloadNodeName',
      label: 'Delivery Location',
      type: 'string',
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'tripStart',
      label: 'Trip Start',
      type: 'datetime',
      enableSort: true,
    },
    {
      name: 'tripEnd',
      label: 'Trip End',
      type: 'datetime',
      enableSort: true,
    },
    {
      name: 'orderProgress',
      label: 'Order',
      type: 'progress',
      enableSort: true,
      hidden: true,
    },
    {
      name: 'taskProgress',
      label: 'Task',
      type: 'progress',
      enableSort: true,
    },
    {
      name: 'stopProgress',
      label: 'Stop',
      type: 'progress',
      enableSort: true,
      hidden: true,
    },
    {
      name: 'status',
      label: 'Status',
      type: 'string',
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'orderNumber',
      label: 'Orders',
      type: 'string',
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'createdAt',
      label: 'Created At',
      type: 'datetime',
      enableSort: true,
      hidden: true,
    },
    {
      name: 'createdBy',
      label: 'Created By',
      type: 'string',
      enableSort: true,
      hidden: true,
    },
  ],
  deleteMessage: (row) => (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Trip:</Typography>
        <Typography>{row.tripNumber}</Typography>
      </Stack>
    </Stack>
  ),
  enableColumnVisibility: true,
  enableColumnGrouping: false,
  enableColumnResizing: true,
  enableDateRangeToggle: true,
  identifier: 'trips',
};

export default function Trips() {
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const navigate = useNavigate();
  const {
    setCurrentPage,
    tablePagination,
    loadRequestPagination,
    updatePagination,
  } = usePagination();
  const {tableSorting, loadRequestSorting} = useSorting({setCurrentPage});
  const {tableFiltering, getFilter} = useFiltering({setCurrentPage});
  const {data, loadDataStatus, reloadData, removeItem} = useLoadTrips({
    getFilter,
    loadRequestPagination,
    loadRequestSorting,
    updatePagination,
  });
  const {deleteStatus, onDelete} = useDeleteTrip({removeItem});
  const {downloadStatus, onDownload} = useDownloadTrips();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <TripsContractFilterField
        onFiltersChange={tableFiltering.onFiltersChange}
      />
      <VantageTablePaper>
        <OverlayLoader
          loading={loadDataStatus === 'idle'}
          label="Preparing content..."
        />
        <OverlayLoader
          loading={loadDataStatus === 'loading'}
          label="Loading trips..."
        />
        <OverlayLoader
          loading={loadDataStatus === 'reloading'}
          label="Reloading trips..."
        />
        <OverlayLoader
          loading={deleteStatus === 'deleting'}
          label="Deleting trip..."
        />
        <OverlayLoader
          loading={downloadStatus === 'downloading'}
          label="Downloading trips..."
        />
        <MemoizedVantageTable<WebMasterTripOptimised>
          config={tableConfig}
          data={data}
          rowId="id"
          toolbar={useMemo(() => ({onRefresh: reloadData}), [reloadData])}
          actions={useMemo(
            () => ({
              onDownload,
              onAdd: hasPermissions('trip:add')
                ? () => {
                    navigate(routes.trip.add);
                  }
                : undefined,
            }),
            [hasPermissions, navigate, onDownload],
          )}
          rowActions={useMemo(
            () => ({
              onAuxNavigate: ({id}) =>
                id != null
                  ? routes.replaceParams(routes.trip.masterTripId, {
                      masterTripId: id,
                    })
                  : null,
              onDelete: hasPermissions('trip:delete') ? onDelete : undefined,
            }),
            [hasPermissions, onDelete],
          )}
          grouping={
            getFilter('contractCode') != null
              ? undefined
              : [{columnName: 'contractCode'}]
          }
          rowDetail={TripsDetail}
          pagination={tablePagination}
          sorting={tableSorting}
          filtering={tableFiltering}
        />
      </VantageTablePaper>
    </div>
  );
}
