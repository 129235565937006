import {useAuth} from 'react-oidc-context';
import {useNavigate, useSearchParams} from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import {config} from '../../../config';
import {routes} from '../../../routes';
import {VantageForm} from '../../common/components/form/VantageForm';
import {BehaviourOutcomesChipList} from '../components/BehaviourOutcomesChipList';
import {EventPastEvents} from '../components/eventPastEvents/EventPastEvents';
import {Behaviours} from '../components/feedback/Behaviours';
import {NotificationContacts} from '../components/feedback/contacts/NotificationContacts';
import {EventDetails} from '../components/feedback/EventDetails';
import {Observations} from '../components/feedback/Observations';
import {VideoSnapshots} from '../components/feedback/VideoSnapshots';
import {useEventReviewContext} from '../contexts/EventReviewContext';
import {useBehaviourOutcomes} from '../hooks/useBehaviourOutcomes';
import {useCheckboxSelectOnKey} from '../hooks/useCheckboxSelectOnKey';
import {useEventFeedback} from '../hooks/useEventFeedback';
import {enhanceFormData} from '../utils/feedbackUtils';

interface Category {
  id?: number;
  name?: string;
}

interface Data {
  behaviourCategory?: Category;
  behaviourType?: Category;
  id?: number;
  name?: string;
  shortcut?: string;
}

export type TableLookup = Record<string, Data>;

export type CategoryWithData<T> = [string, T[]];

export default function EventFeedback() {
  const {
    formRef,
    webSocket,
    snapshotList,
    onEventSelectedShortCuts,
    resetContext,
    notificationComments,
    showOnBreakModal,
    lookupTableData,
  } = useEventReviewContext();
  const {eventDetailsData, generateHindsightData, hindsightConfigData} =
    useEventFeedback();
  const {behaviourOutcomes, getBehaviourOutcomes} = useBehaviourOutcomes();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const auth = useAuth();

  useCheckboxSelectOnKey({
    lookupTable: lookupTableData.combinedLookup,
  });

  const preSelectedFromValues = [
    ...Object.values(lookupTableData.behaviorLookupTable),
  ]
    .concat(Object.values(lookupTableData.observationLookupTable))
    .reduce<Record<string, boolean>>((acc, checkbox) => {
      if (checkbox.shortcut == null || checkbox.name == null) {
        return {};
      }
      if (onEventSelectedShortCuts.includes(checkbox.shortcut)) {
        acc[`__lookupKey: ${checkbox.shortcut}__${checkbox.name}`] = true;
      }
      return acc;
    }, {});

  return (
    <Stack
      m={config.theme.gutterSpacing}
      mt={0}
      sx={{overflowY: 'auto', overflowX: 'hidden'}}
    >
      <EventPastEvents
        vehicleRegistration={eventDetailsData?.vehicle?.registrationNumber}
      />

      <EventDetails eventDetailsData={eventDetailsData} />
      <VantageForm
        FormProps={{
          style: {
            marginBottom: snapshotList.length > 0 ? '20px' : '100px',
          },
        }}
        formInnerRef={formRef}
        initialValues={preSelectedFromValues}
        onSubmit={async (formValues) => {
          const filteredValues = Object.fromEntries(
            Object.entries(formValues).filter(([, value]) => value),
          );

          const {ids} = enhanceFormData(
            filteredValues,
            lookupTableData.behaviorLookupTable,
            lookupTableData.observationLookupTable,
          );

          if (searchParams.get('notifications') == null) {
            void getBehaviourOutcomes(ids).then((inlineOutcomes) => {
              void generateHindsightData(inlineOutcomes);
            });
          }

          if (searchParams.get('notifications') != null) {
            if (behaviourOutcomes != null && behaviourOutcomes?.length > 0) {
              webSocket.sendReview({
                type: 'review',
                eventId: webSocket.events[0].id,
                reviewStart: webSocket.events[0].reviewStartTime,
                reviewEnd: new Date(),
                reviewData: {
                  reviewer: auth.user?.profile.email ?? '',
                  behaviourOutcomes,
                  comments: notificationComments,
                },
                hindsight: hindsightConfigData,
              });
              if (!showOnBreakModal) {
                navigate(routes.eventReview.review);
                searchParams.delete('notifications');
                resetContext();
              }
            }
          } else {
            setSearchParams({notifications: 'true'});
          }
        }}
      >
        {searchParams.get('notifications') == null ? (
          <Grid container spacing={2} mt="0px">
            <Observations
              observations={
                lookupTableData.categorizedObservationsWithShortcuts
              }
            />
            <Behaviours
              behaviours={lookupTableData.categorizedBehaviorsWithShortcuts}
            />
          </Grid>
        ) : (
          <>
            <BehaviourOutcomesChipList outcomes={behaviourOutcomes} />
            <NotificationContacts
              contacts={eventDetailsData?.group?.contacts}
            />
          </>
        )}
      </VantageForm>
      {searchParams.get('notifications') == null ? <VideoSnapshots /> : null}
    </Stack>
  );
}
