import KeyboardIcon from '@mui/icons-material/Keyboard';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {useEventReviewContext} from '../contexts/EventReviewContext';

export function ReviewStatistics() {
  const {lookupTableData} = useEventReviewContext();

  return (
    <ReviewStatisticsBody>
      <ReviewStatisticsHeader>
        <KeyboardIcon color="action" sx={{height: '34px', width: '34px'}} />
        <Typography color="#666666" fontSize="16px" fontWeight={400}>
          Shortcut Keys
        </Typography>
      </ReviewStatisticsHeader>
      <Grid item xs={6} bgcolor="#f5f5f5" borderRadius="12px">
        <Stack borderRadius="16px" p="12px" height="100%">
          <Typography fontSize={16} fontWeight={700} mb="12px">
            BEHAVIOURS SHORTCUTS
          </Typography>
          <Grid container spacing={4}>
            {lookupTableData.categorizedBehaviorsWithShortcuts.map(
              ([category, items]) => {
                if (category?.toLocaleLowerCase() === 'hidden') {
                  return null;
                }
                return (
                  <Grid item xs={6} key={category}>
                    <Typography fontSize={16} fontWeight={700} my={0.5}>
                      {category}
                    </Typography>
                    {items.map((checkbox) => (
                      <Stack
                        flexDirection="row"
                        alignItems="center"
                        key={checkbox.shortcut}
                      >
                        <Typography fontWeight="bold" p={1}>
                          {checkbox.shortcut}{' '}
                        </Typography>
                        <Typography lineHeight={0.5}>
                          <KeyboardDoubleArrowRightIcon />
                        </Typography>
                        <Typography p={1}>{checkbox.name}</Typography>
                      </Stack>
                    ))}
                  </Grid>
                );
              },
            )}
          </Grid>
          <Typography fontSize={16} fontWeight={700} mb="12px" mt="32px">
            OBSERVATIONS SHORTCUTS
          </Typography>
          <Grid container spacing={4}>
            {lookupTableData.categorizedObservationsWithShortcuts.map(
              ([category, items]) => {
                return (
                  <Grid item xs={6} key={category}>
                    <Typography fontSize={16} fontWeight={700} my={0.5}>
                      {category}
                    </Typography>
                    {items.map((checkbox) => (
                      <Stack
                        flexDirection="row"
                        alignItems="center"
                        key={checkbox.shortcut}
                      >
                        <Typography fontWeight="bold" p={1}>
                          {checkbox.shortcut}{' '}
                        </Typography>
                        <Typography lineHeight={0.5}>
                          <KeyboardDoubleArrowRightIcon />
                        </Typography>
                        <Typography p={1}>{checkbox.name}</Typography>
                      </Stack>
                    ))}
                  </Grid>
                );
              },
            )}
            <Grid item xs={6} key="fc">
              <Typography fontSize={16} fontWeight={700} my={0.5}>
                Quick Actions
              </Typography>
              <Stack flexDirection="row" alignItems="center">
                <Typography fontWeight="bold" p={1}>
                  ce
                </Typography>
                <Typography lineHeight={0.5}>
                  <KeyboardDoubleArrowRightIcon />
                </Typography>
                <Typography p={1}>Clip Error</Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center">
                <Typography fontWeight="bold" p={1}>
                  lc
                </Typography>
                <Typography lineHeight={0.5}>
                  <KeyboardDoubleArrowRightIcon />
                </Typography>
                <Typography p={1}>Late Clip</Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center">
                <Typography fontWeight="bold" p={1}>
                  n
                </Typography>
                <Typography lineHeight={0.5}>
                  <KeyboardDoubleArrowRightIcon />
                </Typography>
                <Typography p={1}>No Issue</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </ReviewStatisticsBody>
  );
}

const ReviewStatisticsBody = styled(Stack)`
  background-color: #f5f5f5;
  border-radius: 12px;
  padding: 24px;
  position: relative;
`;

const ReviewStatisticsHeader = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 40px;
  padding-left: 40%;
  padding-bottom: 20px;
  border-bottom: 1px solid #bfbfbf;
`;
