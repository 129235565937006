import {useState} from 'react';

import {type DragEndEvent, type UniqueIdentifier} from '@dnd-kit/core';
import {arrayMove} from '@dnd-kit/sortable';
import type {Listing} from '@onroadvantage/onroadvantage-api';

import {type TripAddStopsOption} from '../components/tripAdd/TripAddStops';
import {type TripAddStopsExistingOrdersValues} from '../components/tripAdd/TripAddStopsExistingOrders';
import {type TripAddStopsDepotValues} from '../components/tripAdd/TripAddStopsSite';
import {getOrdersFromMetaData} from '../helpers/getOrdersFromMetaData';

export interface TripAddStopsOrder {
  id?: number | undefined;
  orderNumber: string;
  upliftPointNode?: Listing;
  offloadPointNode?: Listing;
  product?: Listing;
  purchaseOrder?: Listing;
  customer?: Listing;
  quantity?: number;
  deliveryNoteNumber?: string;
}

export interface TripAddStopsStop {
  sequence: number;
  node: Listing;
  orders?: TripAddStopsOrder[];
}
const getStopPos = (stops: TripAddStopsStop[], id: UniqueIdentifier) =>
  stops.findIndex((stop) => stop.sequence === id);

export const useAddTripStops = () => {
  const [stops, setStops] = useState<TripAddStopsStop[]>([]);
  const [activeOption, setActiveOption] =
    useState<TripAddStopsOption>('Add new order');

  const addDepotToStops = ({node}: TripAddStopsDepotValues) => {
    setStops((prevState) => [
      ...prevState,
      {
        sequence: prevState.length + 1,
        node,
        orders: [],
      },
    ]);
    setActiveOption('Stops overview');
  };

  const addNewOrderToStops = (values: TripAddStopsOrder) => {
    setStops((prevState) => [
      ...prevState,
      {
        sequence: prevState.length + 1,
        node: {},
        orders: [values],
      },
    ]);
    setActiveOption('Stops overview');
  };

  const addExistingOrdersToStops = ({
    existingOrders,
  }: TripAddStopsExistingOrdersValues) => {
    const newOrders = new Map<number, TripAddStopsOrder>();
    const newStops: TripAddStopsStop[] = [];
    existingOrders.forEach((order, index) => {
      const orderFromMetaData = getOrdersFromMetaData(order.metaData);

      if (orderFromMetaData == null) {
        return;
      }

      newStops.push({
        sequence: stops.length + index + 1,
        node: {},
        orders: [{...orderFromMetaData, id: order.value ?? 0}],
      });

      newOrders.set(order.value, orderFromMetaData);
    });

    setStops((prevState) => [
      ...prevState,
      ...newStops.filter((stop) => stop != null),
    ]);
    setActiveOption('Stops overview');
  };

  const onDragEnd = ({active, over}: DragEndEvent) => {
    if (over?.id == null || active.id === over.id) {
      return;
    }
    const originalPosition = getStopPos(stops, active.id);
    const newPosition = getStopPos(stops, over.id);

    setStops((prevState) =>
      arrayMove(prevState, originalPosition, newPosition),
    );
  };

  const onRemoveStop = (id: UniqueIdentifier) => {
    setStops((prevState) =>
      prevState
        .filter((stop) => stop.sequence !== id)
        .map((stop, index) => ({...stop, sequence: index + 1})),
    );
  };

  return {
    activeOption,
    setActiveOption,
    stops,
    addDepotToStops,
    addNewOrderToStops,
    addExistingOrdersToStops,
    onDragEnd,
    onRemoveStop,
  };
};
